export default {
  name: "ListTLoanAccount",
  data() {
    return {
      property: {
        animation: {
          checkingLoanSp3: {
            isLoading: false,
          },
          isDownloadingFile: false,
        },
        modal: {
          showModalCheckingLoanSp3: false,
          showModalResultLoanSp3: false,
        },
        listElement: {
          loanAccount: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            downloading: false,
            message: "",
          },
          loanSp3: {
            rows: 0,
            perPage: 5,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
        filterDto: {
          loanAccountNumber: "",
          cifIdName: "",
        },
      },
      dataForm: {
        loanSp3Checking: {
          cifIdName: "",
          cifNumber: "",
        },
      },
      session: {
        loanSp3Checking: {
          cifIdName: "",
          cifNumber: "",
        },
      },
      table: {
        data: {
          loanAccount: [],
          loanSp3: [],
        },
      },
    };
  },
  methods: {
    async searchLoanAccountByLoanAccountNumber() {
      this.table.data.loanAccount = [];
      this.property.listElement.loanAccount.downloading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "report",
          reqUrl: "sistem-informasi-debitur",
          payload: {
            loanAccountNumber: this.property.filterDto.loanAccountNumber,
            cifIdName: this.property.filterDto.cifIdName,
            page: 0,
            size: this.property.listElement.loanAccount.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanAccount = resp.data.data.content;
            this.property.listElement.loanAccount.rows =
              resp.data.data.totalElements;
            this.property.listElement.loanAccount.currentPage =
              resp.data.data.pageable.pageNumber + 1;
          } else {
            this.failedGetLoanAccount(resp);
          }
        }, 500);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetLoanAccount(error);
        }, 500);
      } finally {
        setTimeout(() => {
          this.property.listElement.loanAccount.downloading = false;
        }, 1000);
      }
    },
    async searchLoanAccountByCifIdName() {
      this.table.data.loanAccount = [];
      this.property.listElement.loanAccount.downloading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "report",
          reqUrl: "sistem-informasi-debitur",
          payload: {
            loanAccountNumber: this.property.filterDto.loanAccountNumber,
            cifIdName: this.property.filterDto.cifIdName,
            page: 0,
            size: this.property.listElement.loanAccount.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanAccount = resp.data.data.content;
            this.property.listElement.loanAccount.rows =
              resp.data.data.totalElements;
            this.property.listElement.loanAccount.currentPage =
              resp.data.data.pageable.pageNumber + 1;
          } else {
            this.failedGetLoanAccount(resp);
          }
        }, 500);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetLoanAccount(error);
        }, 500);
      } finally {
        setTimeout(() => {
          this.property.listElement.loanAccount.downloading = false;
        }, 1000);
      }
    },
    selectLoanData(props) {
      sessionStorage.setItem("LOAN_ACCOUNT_ID", props.row.accountNumber);
      this.$router.push("data");
      console.log(props)
    },
    handleErrorGetLoanAccount(error) {
      console.log(error.response);
      this.table.data.loanAccount = [];
      this.property.listElement.loanAccount.rows = 0;
      this.property.listElement.loanAccount.currentPage = 1;
      this.property.listElement.loanAccount.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async changePaginationLoanAccount(event) {
      this.table.data.loanAccount = [];
      this.property.listElement.loanAccount.downloading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "report",
          reqUrl: "sistem-informasi-debitur",
          payload: {
            loanAccountNumber: this.property.filterDto.loanAccountNumber,
            cifIdName: this.property.filterDto.cifIdName,
            page: event - 1,
            size: this.property.listElement.loanAccount.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanAccount = resp.data.data.content;
            this.property.listElement.loanAccount.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetLoanAccount(resp);
          }
        }, 500);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetLoanAccount(error);
        }, 500);
      } finally {
        setTimeout(() => {
          this.property.listElement.loanAccount.downloading = false;
        }, 500);
      }
    },
    async getLoanAccount() {
      this.table.data.loanAccount = [];
      this.property.listElement.loanAccount.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "report",
          reqUrl: "sistem-informasi-debitur",
          payload: {
            loanAccountNumber: "",
            cifIdName: "",
            page: 0,
            size: 10,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.loanAccount = resp.data.data.content;
            this.property.listElement.loanAccount.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetLoanAccount(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetLoanAccount(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.loanAccount.downloading = false;
        }, timeout);
      }
    },
    failedGetLoanAccount(resp) {
      this.property.listElement.loanAccount.rows = 0;
      this.table.data.loanAccount = [];
      this.property.listElement.loanAccount.message = resp.data.message;
    },
    async onDownloadFile(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DOWNLOADABLE_SISTEM_INFORMASI_DEBITUR",
          fileType: fileType,
          accountNumber: this.property.filterDto.loanAccountNumber,
          cifIdName: this.property.filterDto.cifIdName
        });
        console.log("loanAccountNumber = ", this.property.filterDto.loanAccountNumber)
        console.log("cifIdName = ", this.property.filterDto.cifIdName)
        if (fileType === "pdf") {
          this.printOutWindow(resp.data);
          return;
        }
        var a = document.createElement("a"),
            url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `SistemInformasiDebitur.${
            fileType === "xls" ? "xlsx" : "pdf"
        }`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
      } catch (error) {
        console.log("this is error = ", error);
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
  },
  mounted() {
    this.getLoanAccount();
  },
};
